import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { decode } from 'blurhash';
import styles from './styles.module.css';

interface Props {
  alt: string;
  width: number;
  height: number;
  blurhash?: string;
  src?: string;
}

export const BlurHashImage = ({
  width,
  height,
  alt = '',
  blurhash,
  src = '',
}: Props) => {
  const [placeholder, setPlaceholder] = useState('');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (blurhash) {
      const pixels = decode(blurhash, 32, 32);
      const canvas = document.createElement('canvas');
      canvas.width = 32;
      canvas.height = 32;
      const ctx = canvas.getContext('2d');
      const imageData = ctx?.createImageData(32, 32);
      if (imageData) {
        imageData.data.set(pixels);
        ctx?.putImageData(imageData, 0, 0);
        setPlaceholder(canvas.toDataURL());
      }
    }
  }, [blurhash]);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <div className={styles.imageContainer} style={{ width, height }}>
      {placeholder && (
        <Image src={placeholder} alt={alt} layout="fill" objectFit="cover" />
      )}
      <Image
        className={loaded ? styles.fadeIn : styles.image}
        src={src}
        alt={alt}
        layout="fill"
        objectFit="cover"
        onLoadingComplete={handleLoad}
      />
    </div>
  );
};
