export function formatPluralWord({
  number,
  word,
}: {
  number: number;
  word: string;
}) {
  return `${number} ${number === 1 ? word : word + 's'}`;
}

export function formatPercentage(value: number | string) {
  try {
    return (Number(value) ?? 0)?.toFixed(2) + '%';
  } catch (error) {
    return '0%';
  }
}
