import clsx from 'clsx';
import { Icon } from 'common/icon';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

interface HeaderProps {
  id?: string;
  url?: string;
  name?: string;
  count?: string | number;
  hasIcon?: boolean;
  isReviews?: boolean;
}

export const Header = ({
  id = '',
  url = '',
  name = '',
  count,
  isReviews,
  hasIcon = true,
}: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <Link
      id={id}
      href={hasIcon ? url : ''}
      className={clsx(
        styles.big_wins__link,
        hasIcon && styles.link,
        !hasIcon && styles.notlink,
        isReviews && styles.notlink
      )}
    >
      <h2 className={styles.link__name}>{t(name)}</h2>
      {Number(count) > 0 && (
        <>
          <div className={styles.link__count}>{count}</div>
          {hasIcon && <Icon.Arrow />}
        </>
      )}
    </Link>
  );
};
