import { useAlert } from 'components';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAddToFavoriteMutation,
  useRemoveFromFavoriteMutation,
} from 'store/api/favorite';
import { handleMutation } from 'utils/handleMutation';

export const useFavorites = () => {
  const alert = useAlert();
  const router = useRouter();
  const { t } = useTranslation();

  const [addToFavorite, addMutation] = useAddToFavoriteMutation();
  const [removeFromFavorite, removeMutation] = useRemoveFromFavoriteMutation();

  const isLoading = addMutation.isLoading || removeMutation.isLoading;

  const handleUnauthorized = (e: any) => {
    if (e?.data?.message === 'No access token provided') {
      router?.replace('/login');
      return true;
    }
    return false;
  };

  const onAddSuccess = useCallback(() => {
    alert.success(t('Added to favorite'));
  }, []);

  const onAddError = useCallback((e: any) => {
    if (handleUnauthorized(e)) {
      return;
    }
    alert.error(e?.message || t('Something went wrong'));
  }, []);

  const onRemoveSuccess = useCallback(() => {
    alert.success(t('Removed from favorite'));
  }, []);

  const onRemoveError = useCallback((e: any) => {
    alert.error(e?.message || t('Something went wrong'));
  }, []);

  handleMutation({
    ...addMutation,
    onSuccess: onAddSuccess,
    onError: onAddError,
  });

  handleMutation({
    ...removeMutation,
    onSuccess: onRemoveSuccess,
    onError: onRemoveError,
  });

  return {
    isLoading,
    addToFavorite,
    removeFromFavorite,
  };
};
