import { Icon } from 'common/icon';
import { usePostByIdQuery } from 'store/api/blog_post';

import styles from './styles.module.css';
import Link from 'next/link';
import { Routes } from 'constants/routes';
import { getItemIdentifier } from 'utils/slug';
import { Analytics } from 'constants/analytics';

type Props = {
  id: string;
};

export const GuideItem = ({ id }: Props) => {
  const { data, isFetching } = usePostByIdQuery({ id });

  return (
    <Link
      id={Analytics.collections.guide}
      href={Routes.blogPage(getItemIdentifier(data))}
      className={styles.container}
    >
      <Icon.Guide />
      <span className={styles.text}>
        {data?.title} {isFetching && 'Loading...'}
      </span>
      <Icon.Arrow />
    </Link>
  );
};
