import clsx from 'clsx';

import { Icon } from 'common/icon';
import { Header } from 'components/slider/header';
import { IBigWins } from 'constants/types';

import { isTabletDown } from 'hooks';

interface SliderBigProps {
  url?: string;
  name: string;
  count: string;
  isViewer?: boolean;
  data: IBigWins[] | undefined;
  container?: 'mini' | 'medium';
}

import { BlurHashImage } from 'components/blurhash-image';
import { Routes } from 'constants/routes';
import Link from 'next/link';
import { getItemIdentifier } from 'utils/slug';
import styles from './styles.module.css';

export const Card = ({ data }: { data: IBigWins }) => {
  return (
    <Link
      id={data._id.toString()}
      className={styles.big_wins__item}
      href={Routes.bigwin(getItemIdentifier(data))}
    >
      <BlurHashImage
        width={400}
        height={180}
        alt={data?.name}
        src={data?.imageUrl}
        blurhash={data?.blurHash}
      />

      <div className={styles.big_wins__icon}>
        <Icon.Increase />
      </div>
    </Link>
  );
};

export const CardsBig = ({
  url,
  data,
  name,
  count,
  isViewer,
  container = 'medium',
}: SliderBigProps) => {
  const containerStyles = clsx({
    [styles.container]: container === 'medium',
    [styles.container_s]: container === 'mini',
  });

  const wrapperStyles = clsx(styles.big_wins__row, {
    [styles.big_wins_gallery__row]: isViewer,
  });

  const classeContainer = clsx({
    [styles.big_wins]: !isViewer,
  });

  const filteredData = isTabletDown() ? data?.slice(0, 3) : data;

  return (
    <section className={classeContainer}>
      <div className={containerStyles}>
        <Header name={name} count={count} url={url ?? Routes?.bigwins} />
        <div className={wrapperStyles}>
          {filteredData?.map((item) => <Card key={item._id} data={item} />)}
        </div>
      </div>
    </section>
  );
};
