import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Icon } from 'common/icon';
import { Header } from 'components/slider/header';

import { ICasino } from 'constants/types';

import { LogoOverlay, LogoOverlayWrapper } from 'components/logo-overlay';

import { Loader } from 'common';
import { BlurHashImage } from 'components/blurhash-image';
import { Routes } from 'constants/routes';
import { useFavorites } from 'hooks/useFavorites';
import { useRTPiWeights } from 'hooks/useRTPiWeights';
import Link from 'next/link';
import { getItemIdentifier } from 'utils/slug';
import styles from './styles.module.css';
import { GuideItem } from 'components/guide-item';
import { formatPercentage } from 'utils/formats';

export const Card = ({
  data,
  mobile,
  hasIcon,
  hasFavoritesActions,
}: {
  mobile?: boolean;
  data?: ICasino | null;
  hasIcon?: boolean;
  hasFavoritesActions?: boolean;
}) => {
  const { removeFromFavorite, isLoading } = useFavorites();

  if (!data) {
    return null;
  }

  const { isBad, isGood, isFetching: isRatingFetching } = useRTPiWeights();

  const renderIcon = useCallback(
    (rating: number) => {
      if (isRatingFetching) {
        return <Icon.Cherry fill="#FEFEFE" />;
      }
      if (isBad(rating)) {
        return <Icon.Bad />;
      } else if (isGood(rating)) {
        return <Icon.Good />;
      } else {
        return <Icon.Cherry />;
      }
    },
    [isRatingFetching]
  );

  return (
    <div className={clsx(styles.cards__card, styles.casino_card)}>
      <Link key={data._id} href={Routes.casinoPage(getItemIdentifier(data))}>
        <div className={styles.casino_card__wrapper}>
          <div className={styles.casino_card__cover}>
            <LogoOverlayWrapper>
              <BlurHashImage
                alt=""
                width={180}
                height={mobile ? 230 : 270}
                blurhash={data?.blurHash}
                src={data?.imageUrl ?? ''}
              />

              <LogoOverlay
                width={180}
                height={270}
                alt={data?.name}
                image={data?.logoUrl}
              />
            </LogoOverlayWrapper>
          </div>
          {!hasIcon && (
            <div className={styles.casino_card__rtp}>
              {renderIcon(parseInt(data.rating))}
              <span>{formatPercentage(data.rating)}</span>
            </div>
          )}

          <div className={styles.casino_card__name}>{data?.name}</div>
        </div>

        {hasFavoritesActions && (
          <div className={styles.favorite_action_shadow} />
        )}
      </Link>

      {hasFavoritesActions && (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            removeFromFavorite({ favoriteId: data?._id });
          }}
          className={styles.wrapper_favorite_action}
        >
          {isLoading ? <Loader size="medium" /> : <Icon.Remove />}
        </div>
      )}
    </div>
  );
};

type Props = {
  headerId?: string;
  data?: any;
  url?: string;
  name?: string;
  title?: string;
  guides?: string[];
  container?: string;
  description?: string;
  hasFavoritesActions?: boolean;
  count?: string | number | undefined;
};

export const CardsMedium = ({
  headerId,
  url,
  data,
  name,
  count,
  title,
  guides,
  description,
  hasFavoritesActions,
  container = 'medium',
}: Props) => {
  const { t } = useTranslation();

  const containerStyles = clsx({
    [styles.container]: container === 'medium',
    [styles.container_s]: container === 'mini',
  });

  return (
    <section className={styles.cards}>
      <div className={containerStyles}>
        {title && (
          <div className={styles.cards_header_container}>
            {title && <h1 className={styles.cards__title}>{t(title)}</h1>}
            {description && (
              <h3 className={styles.cards__description}>{description}</h3>
            )}
            <div className={styles.cards_guides_container}>
              {guides?.map((id) => <GuideItem id={id} />)}
            </div>
          </div>
        )}
        {name && (
          <Header
            id={headerId}
            name={name}
            count={count}
            url={url?.length ? url : Routes.allCasinos}
          />
        )}
        {!!data?.length && (
          <div className={styles.cards__row}>
            {data?.map((item: ICasino) => (
              <Card
                data={item}
                key={item?._id}
                hasFavoritesActions={hasFavoritesActions}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export const CardSkeleton = () => {
  return (
    <div className={styles.container_skeleton}>
      <Skeleton width={180} height={270} className={styles.img_skeleton} />

      <div className={styles.wrapper_footer_skeleton}>
        {Array.from({ length: 2 }).map((_, index) => (
          <Skeleton key={index.toString()} width={180} height={20} />
        ))}
      </div>
    </div>
  );
};

export const CardsSkeleton = ({ container = 'medium' }: any) => {
  const containerStyles = clsx({
    [styles.container]: container === 'medium',
    [styles.container_s]: container === 'mini',
  });

  return (
    <div className={containerStyles}>
      <div className={styles.header_skeleton}>
        <Skeleton width={220} height={30} />
      </div>
      <div className={styles.cards__row}>
        {Array.from({ length: 6 }).map((_, index) => (
          <CardSkeleton key={index.toString()} />
        ))}
      </div>
    </div>
  );
};
